import {useRef} from "react";
import LatestEvents from "../components/LatestEvents/LatestEvents";
import UpcomingEvents from "../components/UpcomingEvents/UpcomingEvents";

export default function TestEventsPage({handleEventModalClick}) {
    const upcomingEventsRef = useRef(null);

    return (
        <>
            <div className="relative bg-dark h-full overflow-x-hidden pb-10">
                {/* UPCOMING EVENTS */}
                <div id="upcoming-events" ref={upcomingEventsRef}>
                    <UpcomingEvents handleEventModalClick={handleEventModalClick}/>
                </div>

                {/* LATEST EVENTS */}
                <LatestEvents handleEventModalClick={handleEventModalClick}/>
            </div>
        </>
    )
}