import {useTranslation} from "react-i18next";

export default function DetailsHeader({event}) {
    const {t} = useTranslation()

    return (
        <div className="relative w-100 h-fit min-h-[200px] md:min-h-[230px] bg-dark bg-contain bg-top bg-repeat"
             style={{backgroundImage: `url('${event['banner_src']}')`}}
        >
            <div
                className="bg-transparent z-10 absolute top-0 right-0 bottom-0 left-0 bg-gradient-to-b from-darkHalf from-0% to-darkHalf to-100%"></div>
            <div
                className="relative z-20 h-fit min-h-[200px] md:min-h-[230px] w-full flex flex-col items-center justify-center gap-3">

                {/* EVENT NAME */}
                <h2 className="text-center font-header font-bold text-2xl md:text-5xl text-secondary"
                    style={{textShadow: '0 0.02rem 0.3rem #FF239AFF'}}
                >
                    {event['name']['de'] || event['name']['en']}
                </h2>

                {/* EVENT BOOKING TYPE */}
                <h3 className="text-center font-header font-bold text-1xl md:text-3xl text-white"
                >
                    {t('event_modal.title')}
                </h3>
            </div>
        </div>
    )
}