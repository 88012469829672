import {useRef} from "react";
import About from "../components/About/About";
import Header from "../components/Header/Header";
import LatestEvents from "../components/LatestEvents/LatestEvents";
import UpcomingEvents from "../components/UpcomingEvents/UpcomingEvents";

export default function HomePage({handleEventModalClick}) {
    const upcomingEventsRef = useRef(null);
    const handleButtonClick = () => {
        upcomingEventsRef.current.scrollIntoView({behavior: 'smooth'});
    };

    return (
        <>
            <div className="relative App bg-dark h-full overflow-x-hidden pb-10">
                <div>
                    {/* HEADER */}
                    <Header handleClick={handleButtonClick}/>
                </div>

                {/* UPCOMING EVENTS */}
                <div id="upcoming-events" ref={upcomingEventsRef}>
                    <UpcomingEvents handleEventModalClick={handleEventModalClick}/>
                </div>

                {/* ABOUT SECTION */}
                <About/>

                {/* LATEST EVENTS */}
                <LatestEvents handleEventModalClick={handleEventModalClick}/>
                {/*<Lightning/>*/}
                {/*<iframe className="fixed left-0 bottom-0 z-30"*/}
                {/*        src='https://a4b98af890854bd7936d3c21055836dc.elf.site' width='100%' height='100%'*/}
                {/*        title="sound_control"></iframe>*/}
            </div>
        </>
    )
}