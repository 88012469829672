import LangBtn from "../LangBtn/LangBtn";
import React from "react";
import {useTranslation} from "react-i18next";

export default function LangChoices(props) {
    const {navLinksToLangMap, onLangChange, isToggledLang} = props;
    const {t, i18n} = useTranslation()

    return (
        <>
            {isToggledLang ? (
                <div
                    className="absolute top-16 right-10 md:right-3 w-fit h-fit z-50 text-base font-header list-none bg-transparent text-white rounded-lg shadow"
                    id="language-dropdown-menu">
                    <ul className="font-medium" role="none">
                        {navLinksToLangMap ?
                            Object.keys(navLinksToLangMap).map((item, index) => {
                                if (item.toString() !== i18n.language) {
                                    return (
                                        <li key={item}>
                                            <LangBtn
                                                navLinksToLangMap={navLinksToLangMap}
                                                onLangChange={onLangChange}
                                                item={item}
                                            />
                                        </li>
                                    );
                                }
                            return null;
                        }) : null}
                    </ul>
                </div>
            ) : null}
        </>
    )
}
