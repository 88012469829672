import {useTranslation} from "react-i18next";
import {useEffect} from "react";


export default function LangSwitchBtn(props) {
    const {i18n} = useTranslation();
    const {navLinksToLangMap, handleLangMenu, setIsToggledLang} = props;

    useEffect(() => {
        const handleClick = (e) => {
            let target = e.target;
            if (!target) return;

            if (target.tagName === 'svg' && e.target.parentNode.parentElement.classList.contains('lang-option')) {
                e.preventDefault();
            } else if ((e.target.id !== 'lang-switch' && !e.target.classList.contains('lang-option')) && target.tagName !== 'a') {
                setIsToggledLang(false);
            }
        }

        document.addEventListener("mousedown", handleClick);
        return () => {
            document.removeEventListener("mousedown", handleClick);
        };
    }, [setIsToggledLang]);

    return (
        <>
            <button type="button"
                    className="flex items-center justify-center w-fit px-5 py-3 bg-transparent rounded-md font-body text-start text-white text-sm hover:bg-white hover:text-dark hover:shadow-md hover:shadow-secondary hover:animate-pulse"
                    onClick={handleLangMenu}
                    id="lang-switch"
            >
                {navLinksToLangMap[i18n.language]['buttonElements'][1]}
                {navLinksToLangMap[i18n.language]['buttonElements'][0]}
            </button>
        </>
    )
}