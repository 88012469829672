import axios from 'axios';
import {useTranslation} from "react-i18next";
import React, {useState, useEffect} from 'react';
import MediaStack from "../components/MediaStack/MediaStack";
import LoadingBars from "../components/LoadingBars/LoadingBars";

export default function GalleryPage() {
    const {t} = useTranslation();
    const [mediaStacks, setMediaStacks] = useState([]);
    const [currentStack, setCurrentStack] = useState();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (mediaStacks.length >= 1) {
            setLoading(false);
        }
    }, [mediaStacks]);

    useEffect((stack) => {
        if ((!currentStack) && mediaStacks.length >= 1) {
            setCurrentStack(mediaStacks[0])
        }
        if (stack) {
            setCurrentStack(stack);
        }
    }, [currentStack, mediaStacks]);

    useEffect(() => {
        async function fetchData() {
            return await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/media/stacks/`)
        }

        fetchData().then((res) =>
            setMediaStacks(res.data) && setCurrentStack(res.data[0])
        );
    }, []);

    return (
        <>
            <div className="bg-dark h-full w-full py-40">
                <h2 className="text-white text-center text-2xl md:text-3xl lg:text-4xl font-header">{t('gallery.title')}</h2>
                <div
                    className={`mx-auto text-white grid grid-cols-1 gap-y-40 ${mediaStacks.length === 1 || loading ? "lg:grid-cols-1" : null} py-40 justify-items-center items-center`}>
                    {loading ?
                        <LoadingBars/>
                        :
                        mediaStacks.length === 0 ? (
                            <p className="text-white">{t('events.0')}</p>
                        ) : (
                            mediaStacks.map((stack, index) => (
                                    <div className="mx-auto relative z-30 w-full h-full max-w-[90%] px-4"
                                         key={stack['id']}>
                                        <MediaStack stack={stack}/>
                                    </div>
                                )
                            )
                        )
                    }
                </div>
            </div>
        </>
    );
}