import {useTranslation} from "react-i18next";
import {MultilineTranslation} from "../i18n";

export default function PrivacyPolicyPage() {
    const {t} = useTranslation();

    return (
        <div className="bg-dark w-full py-40">
            <h2 className="text-white text-center text-2xl md:text-3xl lg:text-4xl font-header">{t('privacy_policy.title')}</h2>

            {/* PHOTO UPLOAD */}
            <div className="w-96 md:w-1/2 flex-col mx-auto py-40">
                <div>
                    <MultilineTranslation text={t("privacy_policy.ticket_text")}/>
                </div>

                <div className="h-20"></div>

                <div>
                    <h2 className="text-start font-header text-3xl mb-4 text-secondary">{t("privacy_policy.photo_upload_title")}</h2>
                    <MultilineTranslation text={t("privacy_policy.photo_upload_text")}/>
                </div>
            </div>
        </div>
    );
}
