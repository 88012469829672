export default function Input({name, type, id, placeholder, errors, onChange}) {

    return (
        <div className="py-2 flex flex-col items-start justify-start gap-1">
            <input name={name} type={type} id={id} placeholder={placeholder}
                   className={`py-2 px-4 rounded-md border-none text-dark text-sm md:text-md font-header focus:outline-2 focus:outline-primary w-full`}
                   onChange={onChange}
            />
            {errors && <p className="text-secondary text-sm">
                {errors}
            </p>}
        </div>
    )
}