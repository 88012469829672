import React from "react";
import {Bars} from "react-loader-spinner";

export default function LoadingBars() {
    return (
        <>
            <Bars
                height="80"
                width="80"
                color="white"
                ariaLabel="bars-loading"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
            />
        </>
    )
}