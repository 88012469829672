import {useTranslation} from "react-i18next";

export default function Footer({navLinksToLangMap}) {
    const {t, i18n} = useTranslation();

    return (
        <>
            <footer className="bg-white h-fit py-2 px-4">
                <div className="container mx-auto flex items-center justify-between">
                    <div>
                        <a href="/">
                            <img className="logo mx-auto max-w-xs w-[130px] md:w-[190px]"
                                 src="https://res.cloudinary.com/dvlngrkpe/image/upload/v1678618339/schocker-logo_kpk2tf.png"
                                 alt="schocker.events"
                            />
                        </a>
                    </div>
                    <ul className="text-dark font-header underline ps-0 flex items-center justify-center gap-x-4">
                        <li><a href={`${navLinksToLangMap[i18n.language]["imprint"]}`}>{t("imprint.title")}</a></li>
                        <li><a href={`${navLinksToLangMap[i18n.language]["privacy_policy"]}`}>{t("privacy_policy.title")}</a></li>
                    </ul>
                </div>
            </footer>
        </>
    )
}
