import axios from "axios";

export const placeNewOrder = async ({event, attendees, contactEmail}) => {
    if (event && attendees && contactEmail) {
        const price = parseFloat(event['items'][0]['default_price']);
        const fee = calculateFee(event, event['item_meta_properties']['fees'][0], price);
        const data = {
            "testmode": false,
            "simulate": false,
            "send_email": true,
            "email": contactEmail,
            "sales_channel": "web",
            "fees": [
                {
                    'id': event['item_meta_properties']['fees'][0]['id'],
                    'fee_type': 'payment',
                    'value': fee,
                    'internal_type': 'paypal',
                    'tax_rate': event['item_meta_properties']['fees'][0]['rate'],
                    'tax_rule': event['items'][0]['tax_rule'],
                    '_treat_value_as_percentage': true,
                }
            ],
            "return_url": process.env.REACT_APP_BASE_URL,
            "payment_provider": "paypal",
            "positions": attendees.map((attendee, i) => {
                return {
                    "positionid": i + 1,
                    "item": event['items'][0]['id'],
                    "price": price,
                    "attendee_email": attendee['email'],
                    "attendee_name": `${attendee['first_name']} ${attendee['last_name']}`,
                    "answers": [],
                    "send_email": true,
                }
            }),
        }

        return await axios.post(
            `${process.env.REACT_APP_API_BASE_URL}/api/pretix/place_order/?event=${event['slug']}`,
            data,
        );
    }
}

function calculateFee(event, fee, price) {
    if (fee['_treat_value_as_percentage']) {
        return price / 100 * parseFloat(fee['rate']);
    } else {
        return parseFloat(fee['rate']);
    }
}
