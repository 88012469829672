import {useTranslation} from "react-i18next";

export default function OrderSelection(props) {
    const {t} = useTranslation();
    const {event, numTickets, increaseNumTickets, decreaseNumTickets, handleTogglePOWindow, setAttendees} = props;

    return (
        <div className="grid gird-rows-1 md:grid-cols-1">
            <div className="flex flex-col lg:flex-row items-center justify-between px-5 pt-5 lg:w-3/4 mx-auto gap-4">
                {/* DAYS REMAINING */}
                {event['live'] && event['days_until_start'] > 0 && event['days_until_presale_ends'] <= 0 ? (
                    <div className="w-full flex flex-col md:flex-row items-center justify-center md:justify-between gap-10">
                        <div className="flex items-center justify-center gap-4">
                            <h3 className="text-start font-header font-bold text-md md:text-lg text-secondary">
                                {t("event_modal.days_remaining")}
                            </h3>
                            <p className="text-start font-header font-bold text-sm md:text-md lg:text-lg text-dark">{event['days_until_start'] > 0 ? (event['days_until_start']) : null}</p>
                        </div>
                        <h3 className="text-start font-header font-bold text-md md:text-lg text-secondary">
                            {t("events.evening_sale")}
                        </h3>
                    </div>
                ) : null}

                {/* TICKETS SELECTION */}
                {event['live'] && event['days_until_start'] > 0 && event['days_until_presale_ends'] > 0 ? (
                    <div className="flex flex-col items-center">
                        <div className="flex flex-col-reverse md:flex-row items-center justify-end pb-5 gap-5">
                            <div>
                                {/* BUTTON ( - ) */}
                                <button type="button" data-step="-1"
                                        data-controls="input_item_488836"
                                        aria-label="Decrease quantity" onClick={decreaseNumTickets}
                                        className="w-[40px] px-4 py-2 text-lg bg-white font-header font-bold rounded-s-md cursor-pointer hover:bg-secondary hover:text-white">
                                    <span>-</span></button>

                                {/* INPUT */}
                                <input type="number" inputMode="numeric" pattern="d*"
                                       placeholder="0"
                                       min="0"
                                       max="10" name="item_488836" id="num-tickets--input"
                                       aria-label="Quantity"
                                       value={numTickets}
                                       className="remove-arrow text-lg bg-white text-center font-header font-bold text-secondary"
                                       disabled/>

                                {/* BUTTON ( + ) */}
                                <button type="button" data-step="1"
                                        data-controls="input_item_488836"
                                        aria-label="Increase quantity" onClick={increaseNumTickets}
                                        className="w-[40px] px-4 py-2 text-lg bg-white font-header font-bold rounded-e-md cursor-pointer hover:bg-secondary hover:text-white">
                                    <span>+</span></button>
                            </div>

                            {/* BUTTON ( BUY ) */}
                            <div className="z-30 flex items-center justify-center gap-4">
                                <p className="font-header font-bold">{event["items"] ? event["items"][0]["default_price"] : "..."} {event["currency"]}</p>
                                <div className="relative group">
                                    <button disabled={!numTickets || numTickets === 0}
                                            className="flex items-center justify-center w-fit px-5 py-3 bg-secondary rounded-md font-body text-start text-white text-md md:text-lg lg:text-lg xl:text-xl hover:text-dark hover:bg-white hover:shadow-md hover:shadow-secondary hover:animate-pulse"
                                            onClick={handleTogglePOWindow}
                                    >
                                        {t("event_modal.buy_button")}
                                    </button>
                                    <span
                                        className={`absolute bottom-full mb-2 w-[100px] text-center py-1 px-3 rounded-md bg-primary text-white font-body text-sm hidden ${!numTickets || numTickets === 0 ? "group-hover:block" : ""}`}>
                                        {t("event_modal.buy_button_tooltip")}
                                    </span>
                                </div>
                            </div>
                        </div>

                        {/* QUOTAS */}
                        {event['quotas'] && event['quotas'][0] && event['quotas'][0]['availabilities'] ? (
                                <p className="inline-block font-body font-bold text-dark text-sm md:text-md lg:text-lg xl:text-xl">
                                    {event['quotas'][0]['availabilities']['available_number']} / {event['quotas'][0]['availabilities']['total_size']} {t("event_modal.tickets_available")}
                                </p>
                            ) :
                            <p className="inline-block text-dark">...</p>
                        }
                    </div>
                ) : null}
            </div>
        </div>
    );
};