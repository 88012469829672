import Events from "../Events/Events";
import {useTranslation} from "react-i18next";
import React, {useState, useEffect} from 'react';
import {getUpcomingEvents} from "../PretixAPI";

export default function UpcomingEvents({handleEventModalClick}) {
    const {t} = useTranslation();
    const [upcomingEvents, setUpcomingEvents] = useState([]);

    useEffect(() => {
        let intervalId = null;

        const fetchEvents = async () => {
            const events = await getUpcomingEvents();
            if (events && events.length > 0) {
                setUpcomingEvents(events);
                // Clear the interval if there is data
                if (intervalId) {
                    clearInterval(intervalId);
                }
            }
        };

        // Call once initially
        fetchEvents();

        // Then set up the interval
        intervalId = setInterval(fetchEvents, 5000);

        // Clear the interval in the cleanup function
        return () => {
            if (intervalId) {
                clearInterval(intervalId);
            }
        };
    }, []);

    return (
        <>
            <div className="bg-dark grid grid-cols-1 gap-y-40 justify-items-center pb-40 pt-20">
                <h2 className="text-white text-2xl md:text-3xl lg:text-4xl py-4 font-header">{t('upcoming_events.title')}</h2>
                <Events events={upcomingEvents} handleEventModalClick={handleEventModalClick}/>
            </div>
        </>
    );
}
