import {useState} from "react";
import {changeLanguage} from "i18next";
import LangChoices from "../Languages/LangChoices/LangChoices";
import NavLinksMobile from "./NavLinksMobile/NavLinksMobile";
import NavLinks from "./NavLinks/NavLinks";
import LangTool from "../Languages/LangTool/LangTool";

export default function Navbar(props) {
    const {navItems, navLinksToLangMap} = props;
    const [isToggled, setToggled] = useState(false);
    const [isToggledLang, setIsToggledLang] = useState(false);

    const handleMainMenu = () => {
        setToggled(!isToggled);
    };

    const handleLangMenu = () => {
        setIsToggledLang(!isToggledLang);
    };

    const onLangChange = (lang) => {
        setIsToggledLang(false);
        return changeLanguage(lang);
    }

    return (
        <>
            <nav
                className="fixed top-0 h-fit z-50 w-screen bg-dark text-white font-header font-bold text-lg md:text-xl lg:text-2xl py-2 px-3">
                <div className="w-full flex flex-wrap items-center justify-between">
                    <h1>
                        <a href='/' aria-label='schocker.events'>
                            schocker.events
                        </a>
                    </h1>

                    <NavLinks navItems={navItems} navLinksToLangMap={navLinksToLangMap}/>

                    <LangTool navLinksToLangMap={navLinksToLangMap} handleLangMenu={handleLangMenu} setIsToggledLang={setIsToggledLang} handleMainMenu={handleMainMenu}/>
                </div>

                {/* EXTRA ELEMENTS */}
                <NavLinksMobile navItems={navItems} isToggled={isToggled} navLinksToLangMap={navLinksToLangMap}/>
                <LangChoices navLinksToLangMap={navLinksToLangMap} onLangChange={onLangChange}
                             isToggledLang={isToggledLang}/>
            </nav>
        </>
    )
}