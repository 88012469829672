import axios from 'axios';
import {useTranslation} from "react-i18next";
import {MultilineTranslation} from "../i18n";
import React, {useState, useEffect} from 'react';

export default function ImprintPage() {
    const {t} = useTranslation();
    const [businessData, setBusinessData] = useState([]);

    useEffect(() => {
        async function fetchData() {
            return await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/business/data/`)
        }

        fetchData().then((res) => setBusinessData(res.data));
    }, []);

    return (
        <div className="bg-dark w-full py-40 text-white">

            {/* TITLE */}
            <h2 className="text-white text-center text-2xl md:text-3xl lg:text-4xl font-header">{t('imprint.title')}</h2>

            <div className="w-96 md:w-1/2 flex-col mx-auto py-40">

                {/* CONTACT */}
                <div>
                    <h2 className="text-start font-header text-3xl mb-4 text-secondary">{t("imprint.contact_title")}</h2>
                    <div>
                        <p className="text-start text-white font-body">{businessData.full_name}</p>
                        <p className="text-start text-white font-body">{businessData.address}</p>
                        <p className="text-start text-white font-body">{businessData.zip_code} {businessData.city}</p>
                        <p className="text-start text-white font-body">Tel.: {businessData.phone_number}</p>
                        <p className="text-start text-white font-body">E-Mail: {businessData.email}</p>
                    </div>
                </div>

                <div className="h-20"></div>

                {/* MANAGEMENT */}
                <div>
                    <h2 className="text-start font-header text-3xl mb-4 text-secondary">{t("imprint.management_title")}</h2>
                    <div>
                        <p className="text-start text-white font-body">{businessData.full_name}</p>
                        <MultilineTranslation text={t("imprint.management")}/>
                        <p className="text-start text-white font-body">{businessData.full_name}</p>
                    </div>
                </div>

                <div className="h-20"></div>

                {/* EU TEXT */}
                <div>
                    <h2 className="text-start font-header text-3xl mb-4 text-secondary">{t("imprint.eu_text_title")}</h2>
                    <MultilineTranslation text={t("imprint.eu_text")}/>
                </div>
            </div>
        </div>
    );
}
