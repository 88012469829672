import LangSwitchBtn from "../LangSwitchBtn/LangSwitchBtn";

export default function LangTool(props) {
    const {navLinksToLangMap, handleLangMenu, setIsToggledLang, handleMainMenu} = props;

    return (
        <>
            <div className="relative flex items-center justify-end">
                <LangSwitchBtn
                    navLinksToLangMap={navLinksToLangMap}
                    handleLangMenu={handleLangMenu}
                    setIsToggledLang={setIsToggledLang}
                />

                <button className="md:hidden" onClick={handleMainMenu} type="button"
                        aria-controls="navbar-mobile" aria-expanded="false">
                    <span className="sr-only">Open main menu</span>
                    <svg className="w-8 h-8 text-white" aria-hidden="true"
                         xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <path stroke="currentColor" strokeLinecap="round" strokeWidth="2"
                              d="M5 7h14M5 12h14M5 17h14"/>
                    </svg>
                </button>
            </div>
        </>
    )
}