export default function LangBtn(props) {
    const {navLinksToLangMap, onLangChange, item} = props;

    return (
        <>
            <a href="#"
               className="lang-option flex items-center justify-center w-fit px-5 py-3 bg-white rounded-md font-body font-bold text-start text-dark text-sm hover:bg-secondary hover:text-white hover:shadow-md hover:shadow-primary hover:animate-pulse"
               role="menuitem"
               onClick={(e) => {e.preventDefault(); onLangChange(item);}}
            >
                <div className="lang-option inline-flex items-center">
                    {navLinksToLangMap[item]['buttonElements'][1]}
                    {navLinksToLangMap[item]['buttonElements'][0]}
                </div>
            </a>
        </>
    )
}
