export default function GermanFlagSVG() {
    return (
        <>
            <svg className="w-6 h-6 me-2 shadow-white" enableBackground="new 0 0 512 512"
                 viewBox="0 0 512 512"
                 xmlns="http://www.w3.org/2000/svg">
                <path
                    d="m15.923 345.043c36.171 97.484 130.006 166.957 240.077 166.957s203.906-69.473 240.077-166.957l-240.077-22.26z"
                    fill="#ffda44"/>
                <path
                    d="m256 0c-110.071 0-203.906 69.472-240.077 166.957l240.077 22.26 240.077-22.261c-36.171-97.484-130.006-166.956-240.077-166.956z"/>
                <path
                    d="m15.923 166.957c-10.29 27.733-15.923 57.729-15.923 89.043s5.633 61.31 15.923 89.043h480.155c10.29-27.733 15.922-57.729 15.922-89.043s-5.632-61.31-15.923-89.043z"
                    fill="#d80027"/>
            </svg>
        </>
    )
}