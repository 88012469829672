import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

export default function EventDetails(props) {
    const {
        event,
        handleEventModalClick,
    } = props;


    // Translation

    const {t, i18n} = useTranslation();


    // States

    const [dateFrom, setDateFrom] = useState('...');
    const [dateTo, setDateTo] = useState('...');
    const [location, setLocation] = useState('...');


    // Functions

    const parseEventField = (field) => {
        let fieldString = event[field].replace(/'/g, '"').replace(/,\s*([\]}])/g, '$1');
        if (fieldString) {
            try {
                return JSON.parse(fieldString);
            } catch (error) {
                console.error(`Error parsing JSON: ${fieldString} - ${error.message}`);
            }
        }
    }

    useEffect(() => {
        if (event) {
            let parsedLocationField = parseEventField('location') || {}
            let parsedDateFromField = parseEventField('date_from_parsed') || {}
            let parsedDateToField = parseEventField('date_to_parsed') || {}

            let informalLangKey = `${i18n.language}-informal`
            let langKey = i18n.language || 0

            let locationKey = informalLangKey in parsedLocationField ? informalLangKey : langKey
            let dateFromKey = informalLangKey in parsedDateFromField ? informalLangKey : langKey
            let dateToKey = informalLangKey in parsedDateToField ? informalLangKey : langKey

            setLocation(parsedLocationField[locationKey in parsedLocationField ? locationKey : locationKey === 'en' ? 'de' : 'en'])
            setDateFrom(parsedDateFromField[dateFromKey in parsedDateFromField ? dateFromKey : dateFromKey === 'en' ? 'de' : 'en'])
            setDateTo(parsedDateToField[dateToKey in parsedDateToField ? dateToKey : dateToKey === 'en' ? 'de' : 'en'])
        }
    }, [location, event, i18n.language, parseEventField])

    // Return

    return (
        <>
            <div className="h-5"></div>

            {/* DATE SECTION */}
            <div className="px-2 py-4 grid gird-rows-2 md:px-10 md:grid-cols-1 gap-4">
                <div className="h-full flex flex-col justify-start gap-4">

                    {/* SECTION HEADER */}
                    <h3 className="text-start font-header font-bold text-1xl md:text-2xl lg:text-3xl text-dark">
                        {t('event_modal.date_title')}
                    </h3>

                    <hr className="border-secondary"/>

                    {/* DATES (from-to) */}
                    <h4 className="bg-transparent text-balance rounded-es-md text-start font-body text-xs md:text-md lg:text-lg text-dark">
                        {dateFrom} Uhr - {dateTo} Uhr
                    </h4>
                </div>
            </div>

            <div className="h-5"></div>

            {/* LOCATION SECTION */}
            <div className="px-2 py-4 grid gird-rows-2 md:px-10 md:grid-cols-1 gap-4">
                <div className="h-full flex flex-col justify-start gap-4">

                    {/* SECTION HEADER */}
                    <h3 className="text-start font-header font-bold text-1xl md:text-2xl lg:text-3xl text-dark">
                        {t('event_modal.location_title')}
                    </h3>

                    <hr className="border-secondary"/>

                    {/* LOCATION ADDRESS */}
                    <h4 className="bg-transparent text-balance rounded-es-md text-start font-body text-xs md:text-md lg:text-lg text-dark">
                        {location}
                    </h4>
                </div>

                {/* LOCATION MAP (MAPBOX) */}
                <div className="relative">
                    <img className="rounded-md" src={event['image']} alt="Event image"/>
                </div>
            </div>

            {/* CLOSING */}
            <a className="text-white text-xs md:text-md lg:text-lg border-2 border-white rounded-md p-2 font-header font-bold absolute top-4 right-5 z-20 hover:rotate-3 hover:shadow-md hover:shadow-white"
               href="#" onClick={() => handleEventModalClick(event)}>
                {t('event_modal.close_button')}
            </a>
        </>
    );
};