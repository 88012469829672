import {useTranslation} from 'react-i18next';

function Header({handleClick}) {
    const {t, i18n} = useTranslation();

    return (
        <>
            <header className="relative h-screen bg-dark min-h-fit text-white bg-cover bg-center bg-no-repeat"
                    style={{backgroundImage: "url('https://res.cloudinary.com/dvlngrkpe/image/upload/v1709903565/DJI_0035_gowgic.jpg')"}}
            >
                <video className="absolute z-0 top-0 right-0 left-0 bottom-0 w-full h-full object-cover" autoPlay muted loop>
                    <source src="https://res.cloudinary.com/dmu5iqwom/video/upload/v1713990834/schocker.anniversary/website_anniversary_2_kggdjg.mp4" type="video/mp4"/>
                </video>
                <div
                    className="bg-transparent z-10 absolute top-0 right-0 bottom-0 left-0 bg-gradient-to-b from-dark from-3% to-darkBright to-3%"></div>
                <div className="py-20 md:py-40 px-10 absolute top-0 right-0 bottom-0 left-0 z-40">
                    <div className="grid grid-cols-1 py-20 gap-y-28 xl:gap-y-40 justify-items-center">
                        <img className="logo mx-auto max-w-xs md:max-w-xl lg:max-w-2xl xl:max-w-3xl"
                             src="https://res.cloudinary.com/dvlngrkpe/image/upload/v1678618339/schocker-logo_kpk2tf.png"
                             alt="schocker.events"
                        />

                        {/* UPCOMING EVENT BUTTON */}
                        <a href="#"
                           className="flex items-center justify-center w-fit px-5 py-3 bg-secondary rounded-md font-body text-start text-white text-md md:text-lg lg:text-xl xl:text-2xl hover:text-dark hover:bg-white hover:shadow-md hover:shadow-secondary hover:animate-pulse animate-bounce"
                           onClick={handleClick}
                        >
                            {t("buttons.upcoming_events")}
                        </a>

                        <div
                            className="grid grid-cols-1 gap-y-3 justify-items-center md:justify-items-end md:justify-self-end">
                            <a href="https://t.me/+nxAHNSFrMRE1MDUy" target="_blank"
                               className="flex items-center justify-center w-fit px-5 py-3 bg-primary rounded-md font-body text-start text-dark text-md md:text-lg lg:text-xl xl:text-2xl hover:bg-white hover:shadow-md hover:shadow-primary hover:animate-pulse">
                                <span className="me-2 [&>svg]:h-8 [&>svg]:w-8">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="currentColor"
                                        viewBox="0 0 496 512">
                                      <path
                                          d="M248 8C111 8 0 119 0 256S111 504 248 504 496 393 496 256 385 8 248 8zM363 176.7c-3.7 39.2-19.9 134.4-28.1 178.3-3.5 18.6-10.3 24.8-16.9 25.4-14.4 1.3-25.3-9.5-39.3-18.7-21.8-14.3-34.2-23.2-55.3-37.2-24.5-16.1-8.6-25 5.3-39.5 3.7-3.8 67.1-61.5 68.3-66.7 .2-.7 .3-3.1-1.2-4.4s-3.6-.8-5.1-.5q-3.3 .7-104.6 69.1-14.8 10.2-26.9 9.9c-8.9-.2-25.9-5-38.6-9.1-15.5-5-27.9-7.7-26.8-16.3q.8-6.7 18.5-13.7 108.4-47.2 144.6-62.3c68.9-28.6 83.2-33.6 92.5-33.8 2.1 0 6.6 .5 9.6 2.9a10.5 10.5 0 0 1 3.5 6.7A43.8 43.8 0 0 1 363 176.7z"/>
                                    </svg>
                                </span>
                                {t("buttons.telegram")}
                            </a>
                            <a href="https://www.instagram.com/schocker.events/" target="_blank"
                               className="flex items-center justify-center w-fit px-5 py-3 bg-secondary rounded-md font-body text-start text-white text-md md:text-lg lg:text-xl xl:text-2xl hover:text-dark hover:bg-white hover:shadow-md hover:shadow-secondary hover:animate-pulse">
                                <span className="me-2 [&>svg]:h-8 [&>svg]:w-8">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="currentColor"
                                        viewBox="0 0 448 512">
                                      <path
                                          d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"/>
                                    </svg>
                                </span>
                                {t("buttons.instagram")}
                            </a>
                        </div>
                    </div>
                </div>
            </header>
        </>
    );
}

export default Header;
