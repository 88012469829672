import {useEffect, useState} from "react";
import {getUpcomingEvents} from "../components/PretixAPI";

export default function BuyTicketsPage() {
    const [saleEvents, setSaleEvents] = useState([]);

    const fetchEvents = async () => {
        const events = await getUpcomingEvents();
        if (events && events.length >= 1) {
            setSaleEvents(events);
        }
    };

    useEffect(() => {
        let intervalId = null;

        fetchEvents();

        intervalId = setInterval(fetchEvents, 5000);

        return () => {
            if (intervalId) {
                clearInterval(intervalId);
            }
        };
    }, []);

    useEffect(() => {
        if (saleEvents && saleEvents.length >= 1) {
            window.location.href = saleEvents[0].public_url;
        }
    }, [saleEvents]);

    return null;
}