import i18n from 'i18next';
import detector from "i18next-browser-languagedetector";
import backend from "i18next-http-backend";
import global_en from "./translations/en/global.json";
import global_de from "./translations/de/global.json";
import {initReactI18next} from "react-i18next";

i18n
    .use(detector)
    .use(backend)
    .use(initReactI18next)
    .init({
        interpolation: {escaleValue: false},
        lng: localStorage.getItem('i18nextLng') || 'de',
        debug: false,
        fallbackLng: "de",
        resources: {
            en: {
                global: global_en,
            },
            de: {
                global: global_de,
            },
        },
    })

export default i18n;

export function MultilineTranslation({text}) {
  const items = text.split('\n')
  return (
    <div>
        { items.map((item, index) => <p key={index} className="text-start text-white font-body">{item}</p>)}
    </div>
  )
}