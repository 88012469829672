import {useTranslation} from "react-i18next";
import React from "react";

export default function NavLinks(props) {
    const {navItems, navLinksToLangMap} = props;
    const {t, i18n} = useTranslation();

    return (
        <>
            <ul className="hidden md:flex flex-row font-header rounded-lg text-white bg-dark md:space-x-8 rtl:space-x-reverse">
                {navLinksToLangMap && navItems ?
                    navItems.map((item, index) => (
                        <li key={index}>
                            <a href={`${navLinksToLangMap[i18n.language][item]}`}
                               className={`mx-2 block px-4 py-2 text-white  border-[1px] border-white rounded-md hover:bg-white hover:text-dark ${index % 2 === 1 ? 'hover:shadow-md hover:shadow-primary' : 'hover:shadow-md hover:shadow-secondary'}`}
                               aria-current="page">
                                {t(`navbar.links.${item}`)}
                            </a>
                        </li>
                )) : null}
            </ul>
        </>
    )
}