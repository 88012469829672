import {useTranslation} from "react-i18next";
import {motion, useAnimation} from 'framer-motion';
import {InView} from 'react-intersection-observer';
import LoadingBars from "../LoadingBars/LoadingBars";
import React, {useState, useEffect, forwardRef} from 'react';

const AnimatedDiv = forwardRef(({index, inView}, ref) => {
    const controls = useAnimation();

    useEffect(() => {
        if (inView) {
            controls.start("jump");
        }
    }, [controls, inView]);

    const jumpAnimation = (index) => {
        return {
            jump: {
                scale: [0.2, 1.2, 1],
                rotate: [index % 2 === 1 ? 1 : -3, index % 2 === 1 ? -3 : 1],
            }
        };
    };

    const jumpTransition = {
        duration: 0.6,
        times: [0.2, 0.5, 1],
        ease: "easeInOut",
        loop: Infinity,
        delay: 0.3,
    };

    return (
        <motion.div
            ref={ref}
            className={`absolute -z-10 top-[25%] left-0 right-0 h-[50%] w-[100%] ${index % 2 === 1 ? 'bg-secondary' : 'bg-primary'}`}
            variants={jumpAnimation(index)}
            initial="start"
            animate={controls}
            transition={jumpTransition}
        />
    );
});

function Events({events, handleEventModalClick}) {
    const {t} = useTranslation();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (events) {
            setLoading(false);
        }
    }, [events]);

    return (
        <>
            {loading ? (
                <LoadingBars/>
            ) : events.length === 0 ? (
                <p className="text-white text-center">{t('events.no_events')}</p>
            ) : (
                events.map((event, index) => (
                    !event.testmode ? (
                        <div
                            className={`relative z-30 bg-transparent w-fit h-fit max-w-[90%] px-4 cursor-pointer hover:scale-95 ${index % 2 === 0 ? "hover:rotate-3" : "hover:-rotate-3"}`}
                            key={index}
                            onClick={() => handleEventModalClick(event)}
                        >
                            <img className="mx-auto w-[95%] h-[204px] object-cover"
                                 src={event.banner_src}
                                 alt={event.name}
                            />

                            {/* SALE BADGE */}
                            {event.live && event.testmode === false && event.days_until_presale_ends >= 1  && event.days_until_start > 0 ? (
                                <div
                                    className="absolute z-40 top-1 left-8 bg-success px-4 py-2 rounded-md animate-bounce">
                                    <p className="text-xs font-header font-bold">
                                        {t('events.sale')}
                                    </p>
                                </div>
                            ) : null}

                            {/* EVENING SALE BADGE */}
                            {event.live && event.testmode === false && event.days_until_presale_ends <= 1 && event.days_until_start > 0 ? (
                                <div
                                    className="absolute z-40 top-1 left-8 bg-orange-500 px-4 py-2 rounded-md animate-bounce">
                                    <p className="text-xs font-header font-bold text-white">
                                        {t('events.evening_sale')}
                                    </p>
                                </div>
                            ) : null}

                            {/* TESTMODE BADGE */}
                            {event.live && event.testmode === true && event.days_until_start !== -1 ? (
                                <div
                                    className="absolute z-40 top-1 left-8 bg-yellow-500 px-4 py-2 rounded-md">
                                    <p className="text-xs font-header font-bold text-white">
                                        {t('events.testmode')}
                                    </p>
                                </div>
                            ) : null}

                            {/* PAST */}
                            {event.live && event.testmode === false && event.days_until_start === -1 ? (
                                <div
                                    className="absolute z-40 top-1 left-8 bg-white px-4 py-2 rounded-md">
                                    <p className="text-xs font-header font-bold text-dark">
                                        {t('events.ended')}
                                    </p>
                                </div>
                            ) : null}

                            <InView triggerOnce>
                                {({inView, ref}) => (
                                    <AnimatedDiv index={index} inView={inView} ref={ref}/>
                                )}
                            </InView>
                        </div>
                    ) : null
                ))
            )}
        </>
    );
}

export default Events;
