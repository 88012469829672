import PlaceOrderForm from "./PlaceOrderForm";
import {useTranslation} from "react-i18next";

export default function PlaceOrderWindow(props) {
    const {t} = useTranslation();
    const {toggledPOWindow, handleTogglePOWindow, event, order, setOrder, numTickets, setNumTickets, attendees, setAttendees} = props;

    return toggledPOWindow ? (
        <div className="absolute z-30 top-0 right-0 left-0 bottom-0 bg-dark p-10 overflow-y-scroll">
            {/* ORDER FORM */}
            <PlaceOrderForm event={event} order={order} setOrder={setOrder} numTickets={numTickets} setNumTickets={setNumTickets} attendees={attendees} setAttendees={setAttendees}/>

            {/* CLOSE BUTTON */}
            {/* CLOSING */}
            <a className="text-white text-xs md:text-md lg:text-lg border-2 border-white rounded-md p-2 font-header font-bold absolute top-4 right-5 z-20 hover:rotate-3 hover:shadow-md hover:shadow-white"
               href="#" onClick={() => handleTogglePOWindow()}>
                {t("event_modal.close_button")}
            </a>
        </div>
    ) : null;
}