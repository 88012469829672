import {useEffect, useState} from "react";
import EventDetails from "./EventDetails";
import PlaceOrderWindow from "./PlaceOrderWindow";
import DetailsHeader from "./EventDetails/DetailsHeader";
import OrderSelection from "./EventDetails/OrderSelection";

export default function EventsModal(props) {
    const {openEventModal, event, handleEventModalClick, setOpenEventModal} = props;

    // States
    const [toggledPOWindow, setToggledPOWindow] = useState(false);
    const [numTickets, setNumTickets] = useState(() => Number(localStorage.getItem('numTickets')) || 0);
    const [order, setOrder] = useState({});
    const [attendees, setAttendees] = useState(() => {
        const savedAttendees = localStorage.getItem('attendees');
        const parsedAttendees = savedAttendees ? JSON.parse(savedAttendees) : [];
        return parsedAttendees.length > 0 ? parsedAttendees : Array(numTickets).fill({}).map((attendee, index) => ({
            ...attendee,
        }));
    });


    // Functions

    const handleTogglePOWindow = () => {
        const eventModal = document.getElementById('eventModal')
        if (!toggledPOWindow) {
            setToggledPOWindow(true);
            eventModal.classList.add('overflow-y-hidden')
            eventModal.scrollTop = 0;
        } else {
            setToggledPOWindow(false);
            document.getElementById('eventModal').classList.toggle('overflow-y-hidden')
        }
    }

    const decreaseNumTickets = () => {
        if (numTickets > 0) {
            setNumTickets(numTickets - 1);
            localStorage.setItem('numTickets', numTickets - 1);
            setAttendees(prevAttendees => prevAttendees.slice(0, -1));
        }
    }

    const increaseNumTickets = () => {
        if (numTickets < 10) {
            setNumTickets(numTickets + 1);
            localStorage.setItem('numTickets', numTickets + 1);
            setAttendees(prevAttendees => [...prevAttendees, {}]);
        }
    }

    useEffect(() => {
        if (order && order['payments'] && order['payments'][0]) {
            window.open(order['payments'][0]['payment_url'], '_blank', 'height=600,width=800');
        }
    }, [order]);

    // Return

    return openEventModal && event ? (
        <div className="bg-darkHalf min-h-screen fixed top-0 right-0 left-0 bottom-0 z-40">
            <div id="eventModal"
                 className="z-50 absolute top-[15%] bottom-[15%] right-[10%] left-[10%] md:top-[15%] lg:right-[20%] lg:left-[20%] bg-whiteHalf border-2 border-white rounded-md overflow-y-auto">
                <div onClose={() => setOpenEventModal(false)} className="h-auto">
                    <DetailsHeader event={event}/>

                    <OrderSelection event={event} numTickets={numTickets} increaseNumTickets={increaseNumTickets}
                                    decreaseNumTickets={decreaseNumTickets}
                                    handleTogglePOWindow={handleTogglePOWindow} setAttendees={setAttendees}/>

                    <EventDetails
                        handleEventModalClick={handleEventModalClick}
                        order={order}
                        event={event}
                        setNumTickets={setNumTickets}
                    />
                </div>

                <PlaceOrderWindow
                    toggledPOWindow={toggledPOWindow}
                    handleTogglePOWindow={handleTogglePOWindow}
                    event={event}
                    order={order}
                    setOrder={setOrder}
                    numTickets={numTickets}
                    setNumTickets={setNumTickets}
                    attendees={attendees}
                    setAttendees={setAttendees}
                />
            </div>
        </div>
    ) : null;
}