import './App.css';
import {motion} from "framer-motion";
import HomePage from "./pages/HomePage";
import EventsPage from "./pages/EventsPage";
import {useTranslation} from "react-i18next";
import GalleryPage from "./pages/GalleryPage";
import ImprintPage from "./pages/ImprintPage";
import Footer from "./components/Footer/Footer";
import React, {useEffect, useState} from "react";
import EventsModal from "./components/EventsModal";
import PrivacyPolicyPage from "./pages/PrivacyPolicyPage";
import Navbar from "./components/navigation/Navbar/Navbar";
import {createBrowserRouter, RouterProvider} from 'react-router-dom';
import GermanFlagSVG from "./components/navigation/Languages/Flags/GermanFlagSVG";
import EnglishFlagSVG from "./components/navigation/Languages/Flags/EnglishFlagSVG";
import TestEventsPage from "./pages/TestEventsPage";
import ErrorBoundary from "./components/ErrorBoundary";
import BuyTicketsPage from "./pages/BuyTicketsPage";

function App() {
    const {t} = useTranslation()
    const [footerHeight, setFooterHeight] = useState(0);
    const [openEventModal, setOpenEventModal] = useState(false);
    const [currEventOpen, setCurrEventOpen] = useState(null);

    const navItems = ['home', 'events', 'gallery'];
    const navLinksToLangMap = {
        'en': {
            'buttonElements': ['English (EN)', <EnglishFlagSVG/>],
            'home': '/',
            'test_events': '/test-events',
            'events': `/${t('navbar.links.events', {lng: 'en'})}`,
            'gallery': `/${t('navbar.links.gallery', {lng: 'en'})}`,
            'imprint': `/${t('imprint.title', {lng: 'en'})}`,
            'privacy_policy': `/${t('privacy_policy.title', {lng: 'en'})}`,
            'tickets': `/${t('tickets.title', {lng: 'en'})}`,
        },
        'de': {
            'buttonElements': ['Deutsch (DE)', <GermanFlagSVG/>],
            'home': '/',
            'test_events': '/test-events',
            'events': `/${t('navbar.links.events', {lng: 'de'})}`,
            'gallery': `/${t('navbar.links.gallery', {lng: 'de'})}`,
            'imprint': `/${t('imprint.title', {lng: 'de'})}`,
            'privacy_policy': `/${t('privacy_policy.title', {lng: 'de'})}`,
            'tickets': `/${t('tickets.title', {lng: 'de'})}`,
        }
    }

    const handleEventModalClick = (event) => {
        if (!openEventModal) {
            setOpenEventModal(true);
            setCurrEventOpen(event);
        } else {
            setOpenEventModal(false);
            setCurrEventOpen(null);
        }
    }

    const router = createBrowserRouter([
        {
            path: navLinksToLangMap['de']['home'],
            element: <HomePage handleEventModalClick={handleEventModalClick}/>,
        },
        {
            path: navLinksToLangMap['en']['home'],
            element: <HomePage handleEventModalClick={handleEventModalClick}/>,
        },
        {
            path: navLinksToLangMap['de']['events'],
            element: <EventsPage handleEventModalClick={handleEventModalClick}/>,
        },
        {
            path: navLinksToLangMap['en']['events'],
            element: <EventsPage handleEventModalClick={handleEventModalClick}/>,
        },
        {
            path: navLinksToLangMap['de']['gallery'],
            element: <GalleryPage/>,
        },
        {
            path: navLinksToLangMap['en']['gallery'],
            element: <GalleryPage/>,
        },
        {
            path: navLinksToLangMap['en']['imprint'],
            element: <ImprintPage/>,
        },
        {
            path: navLinksToLangMap['de']['imprint'],
            element: <ImprintPage/>,
        },
        {
            path: navLinksToLangMap['en']['privacy_policy'],
            element: <PrivacyPolicyPage/>,
        },
        {
            path: navLinksToLangMap['de']['privacy_policy'],
            element: <PrivacyPolicyPage/>,
        },
        {
            path: navLinksToLangMap['de']['test_events'],
            element: <TestEventsPage handleEventModalClick={handleEventModalClick}/>,
        },
        {
            path: navLinksToLangMap['en']['test_events'],
            element: <TestEventsPage handleEventModalClick={handleEventModalClick}/>,
        },
        {
            path: navLinksToLangMap['de']['tickets'],
            element: <BuyTicketsPage/>,
        },
        {
            path: navLinksToLangMap['en']['tickets'],
            element: <BuyTicketsPage/>,
        },
    ]);

    const routeVariants = {
        initial: {
            x: '-100vw'
        },
        final: {
            x: '0vw'
        }
    }

    useEffect(() => {
        const handleResize = () => {
            const footerElement = document.getElementsByTagName('footer').item(0);
            if (footerElement) {
                setFooterHeight(footerElement.offsetHeight);
            }
        };

        handleResize();
        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <>
            <ErrorBoundary>
                <div className="App bg-dark overflow-x-hidden"
                     style={{minHeight: `calc(100vh - ${footerHeight}px)`}}>
                    <Navbar navItems={navItems} navLinksToLangMap={navLinksToLangMap}/>
                    <div className="lg:max-w-[80%] mx-auto">
                        {router && (
                            <motion.div variants={routeVariants}
                                        initial="initial"
                                        animate="final"
                                        transition={{type: "spring", stiffness: 100}}
                            >
                                <RouterProvider router={router}/>
                            </motion.div>
                        )}
                    </div>
                </div>
                <EventsModal openEventModal={openEventModal} event={currEventOpen}
                             handleEventModalClick={handleEventModalClick}
                             setOpenEventModal={setOpenEventModal}/>
                <Footer navLinksToLangMap={navLinksToLangMap}/>
            </ErrorBoundary>
        </>
    );
}

export default App;
