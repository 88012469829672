import {animated, useSpring} from "@react-spring/web";
import {useTranslation} from "react-i18next";

export default function NavLinksMobile(props) {
    const {navItems, isToggled, navLinksToLangMap} = props;
    const {t, i18n} = useTranslation();

    const spring = useSpring({
        opacity: isToggled ? 1 : 0,
        transform: isToggled ? 'translateY(0px)' : 'translateY(-200px)',
        config: {duration: 250, reverse: !isToggled},
    });

    return (
        <>
            {isToggled ? (
                <animated.div className="items-center justify-between w-full md:hidden"
                              id="navbar-mobile"
                              style={spring}
                >
                    <ul className="flex flex-col py-1 px-4 font-header md:space-x-8 rtl:space-x-reverse">
                        {navItems.map((item, index) => (
                            <li key={index} className="my-1">
                                <a href={`${navLinksToLangMap[i18n.language][item]}`}
                                   className={`text-center mx-2 block px-4 py-2 text-white border-[1px] border-white rounded-md hover:bg-white hover:text-dark ${index % 2 === 1 ? 'hover:shadow-md hover:shadow-primary' : 'hover:shadow-md hover:shadow-secondary'}`}
                                   aria-current="page">
                                    {t(`navbar.links.${item}`)}
                                </a>
                            </li>
                        ))}
                    </ul>
                </animated.div>) : null}
        </>
    )
}