import {motion, useAnimation} from 'framer-motion';
import React, {forwardRef, useEffect, useState} from "react";
import {InView} from "react-intersection-observer";
import LoadingBars from "../LoadingBars/LoadingBars";

const AnimatedMedia = forwardRef(({index, inView, item}, ref) => {
    const controls = useAnimation();

    useEffect(() => {
        if (inView) {
            return controls.start("jump");
        }
    }, [controls, inView]);

    const jumpAnimation = (index) => {
        return {
            jump: {
                scale: [0.2, 1.2, 1],
                rotate: [index % 2 === 1 ? 1 : -1, index % 2 === 1 ? -1 : 1],
            }
        }
    };

    const jumpTransition = {
        duration: 1,
        times: [0.2, 0.5, 1],
        ease: "easeInOut",
        loop: Infinity,
        delay: index / 10,
    };

    return (
        <motion.div
            ref={ref}
            variants={jumpAnimation(index)}
            initial="start"
            animate="jump"
            transition={jumpTransition}
            className="w-fit max-w-full mx-auto mb-10 shadow-media">
            {item.media_type === 'IMAGE' ? (
                <img src={item['full_url']} alt={item['name']}/>
            ) : (
                <video className="max-h-[500px] w-fit" src={item['full_url']} controls/>
            )}
        </motion.div>
    );
});


export default function MediaStack(props) {
    const {stack} = props;
    const [isToggled, setToggled] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (stack['media_items'] && stack['media_items'].length >= 1) {
            setLoading(false);
        }
    }, [stack]);

    const handleStackToggle = (e) => {
        setToggled(!isToggled);

        setTimeout(() => {
            const elementId = "items_grid-" + stack['name'];
            const element = document.getElementById(elementId);

            if (element) {
                if (!isToggled) {
                    element.scrollIntoView({behavior: 'smooth', block: 'center', inline: 'center'});
                }
            }
        }, 0);
    };

    return (
        <>
            <div className='flex flex-col justify-center items-center h-full w-full'>
                {
                    loading ?
                        <LoadingBars/>
                        :
                        <>
                            <div>
                                <div className='stack h-72 w-72 flex items-center justify-center relative'
                                     onClick={e => handleStackToggle(e)}
                                >
                                    {
                                        stack['media_items'].map((item, index) => (
                                            <div key={item['name'] + '-' + index}
                                                 className={`absolute top-0 left-0 right-0 bottom-0 cursor-pointer rounded-md -z-${index}0 transform transition-transform duration-500 bg-no-repeat bg-cover bg-center
                                            ${index === stack['media_items'].length - 1 ? "border-2 border-secondary rotate-0" : "shadow-sm shadow-primary"}
                                            ${index % 2 === 0 ? "-rotate-6 hover:rotate-6" : "rotate-6 hover:-rotate-6"}`}
                                                 style={{
                                                     backgroundImage: `url(${item["media_type"] === "IMAGE" ? item["full_url"] : item["image_layer_url"]})`,
                                                 }}
                                            >
                                            </div>
                                        ))
                                    }
                                </div>
                                <div>
                                    <h2 className="text-primary font-bold text-center font-header text-2xl mt-10">{stack['title']}</h2>
                                </div>
                            </div>

                            {
                                isToggled ?
                                    <div id={"items_grid-" + stack['name']} className="columns-2 my-40">
                                        {stack['media_items'].map((item, index) => (
                                            <a href={item['full_url']} target="_blank" key={item['name'] + '--' + index}
                                               className="cursor-pointer hover:scale-95" rel="noreferrer">
                                                <InView triggerOnce>
                                                    {({inView, ref}) => (
                                                        <AnimatedMedia key={item['name']} index={index}
                                                                       inView={inView}
                                                                       item={item}/>
                                                    )}
                                                </InView>
                                            </a>
                                        ))}
                                    </div>
                                    :
                                    null
                            }
                        </>
                }
            </div>
        </>
    )
}