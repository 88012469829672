import axios from 'axios';
import {useTranslation} from "react-i18next";
import Events from "../components/Events/Events";
import React, {useState, useEffect} from 'react';
import LoadingBars from "../components/LoadingBars/LoadingBars";

export default function EventsPage({handleEventModalClick}) {
    const {t} = useTranslation();
    const [upcomingEvents, setUpcomingEvents] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        async function fetchData() {
            try {
                const res = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/pretix/all_events/`);
                setUpcomingEvents(res.data);
            } catch (error) {
                console.error("Error fetching events:", error);
            } finally {
                setLoading(false);
            }
        }

        fetchData();
    }, []);

    return (
        <>
            <div className="bg-dark w-full py-40">
                <h2 className="text-white text-center text-2xl md:text-3xl lg:text-4xl font-header">{t('all_events.title')}</h2>
                {loading ? (
                    <div className="py-40 flex items-center justify-center">
                        <LoadingBars/>
                    </div>
                ) : upcomingEvents.length === 0 ? (
                    <div className="py-40 flex items-center justify-center">
                        <p className="text-white text-center text-xl">{t('events.no_events')}</p>
                    </div>
                ) : (
                    <div
                        className="grid grid-cols-1 gap-y-40 lg:grid-cols-2 py-40 justify-items-center items-center mx-auto">
                        <Events events={upcomingEvents} handleEventModalClick={handleEventModalClick}/>
                    </div>
                )}
            </div>
        </>
    );
}
