import axios from "axios";

export const getLatestEvents = async () => {
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/pretix/all_ended_events/`);
        return res.data.slice(0, 3);
    } catch (err) {
        if (process.env.NODE_ENV === 'development') {
            console.log(err);
        }
        return [];
    }
}

export const getUpcomingEvents = async () => {
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/pretix/all_upcoming_events/`)
        return res.data;
    } catch (err) {
        if (process.env.NODE_ENV === 'development') {
            console.log(err);
        }
        return [];
    }
}

export const getSaleEvents = async () => {
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/pretix/get_all_sale_events/`)
        console.log(res.data)
        return res.data;
    } catch (err) {
        if (process.env.NODE_ENV === 'development') {
            console.log(err);
        }
        return [];
    }
}