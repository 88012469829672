import Input from "../../Input";
import {useEffect, useState} from "react";
import {placeNewOrder} from "../api";
import {useTranslation} from "react-i18next";

export default function PlaceOrderForm({event, order, setOrder, numTickets, setNumTickets, attendees, setAttendees}) {
    const {t} = useTranslation();
    const [contactEmail, setContactEmail] = useState('');
    const [errors, setErrors] = useState({});
    const [isFormValid, setIsFormValid] = useState(false);

    const validateField = (name, value) => {
        let newErrors = {};

        if (!value) {
            newErrors[name] = t("place_order_window.field_is_required");
        } else {
            newErrors[name] = '';

            if (name.startsWith('attendee__email') || name.startsWith('contact_email')) {
                const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                if (!emailRegex.test(value)) {
                    newErrors[name] = t("place_order_window.invalid_email");
                }
            }

            if (name.startsWith('attendee__first_name') || name.startsWith('attendee__last_name')) {
                const nameRegex = /^[a-zA-Z\u0080-\uFFFF]+$/;
                if (!nameRegex.test(value)) {
                    newErrors[name] = t("place_order_window.invalid_name");
                }
                if (value.length < 2) {
                    newErrors[name] = t("place_order_window.name_to_short");
                }
            }
        }

        return newErrors;
    };

    const handleChange = (e) => {
        const {name, value} = e.target;

        const newErrors = validateField(name, value);
        setErrors(errors => ({...errors, ...newErrors}));

        if (name === 'contact_email') {
            setContactEmail(value);
        }

        if (name.startsWith('attendee__')) {
            const [_, field, index] = name.split('__');

            setAttendees(attendees.map((attendee, i) => {
                attendee[field] = attendee[field] || '';
                if (i === Number(index)) {
                    return {...attendee, [field]: value};
                } else {
                    return attendee;
                }
            }), () => {
                localStorage.setItem('attendees', JSON.stringify(attendees));
            });
        }
    };

    function checkErrorsEmpty(dictionary) {
        for (let key in dictionary) {
            if (dictionary[key] !== '') {
                setIsFormValid(false);
                return false;
            }
        }
        setIsFormValid(true);
        return true;
    }


    const handleSubmit = (e) => {
        e.preventDefault();

        let newErrors = {};

        newErrors = {...newErrors, ...validateField('contact_email', contactEmail)};

        attendees.forEach((attendee, i) => {
            newErrors = {...newErrors, ...validateField('attendee__first_name__' + i, attendee['first_name'])};
            newErrors = {...newErrors, ...validateField('attendee__last_name__' + i, attendee['last_name'])};
            newErrors = {...newErrors, ...validateField('attendee__email__' + i, attendee['email'])};
        });

        setErrors(newErrors);

        if (checkErrorsEmpty(newErrors)) {
            // Create new order and set response object to state
            placeNewOrder({event, attendees, contactEmail}).then((response) => {
                if (response) {
                    setOrder(response.data);
                    // Reset form fields after successful order
                    setContactEmail(null);
                    setAttendees(Array(numTickets).fill({}));
                    setNumTickets(1);
                }
            });
        }
    };

    const removeAttendee = (index) => {
        setAttendees(attendees.filter((_, i) => i !== index));
    }

    useEffect(() => {
        if (attendees && attendees.length > 0) {
            localStorage.setItem('attendees', JSON.stringify(attendees));
        }
        if (numTickets > attendees.length && numTickets !== attendees.length) {
            setNumTickets(attendees.length);
        }
    }, [attendees, numTickets, setNumTickets]);

    useEffect(() => {
        const savedAttendees = JSON.parse(localStorage.getItem('attendees'));

        if (savedAttendees) {
            if (savedAttendees.length !== numTickets) {
                const updatedAttendees = Array(numTickets).fill({}).map((_, index) => {
                    return savedAttendees[index] ? savedAttendees[index] : {};
                });
                setAttendees(updatedAttendees);
                localStorage.setItem('attendees', JSON.stringify(updatedAttendees));
            } else {
                setAttendees(savedAttendees);
                savedAttendees.forEach((attendee, index) => {
                    document.getElementsByName('attendee__email__' + index)[0].value = attendee.email || '';
                    document.getElementsByName('attendee__first_name__' + index)[0].value = attendee.first_name || '';
                    document.getElementsByName('attendee__last_name__' + index)[0].value = attendee.last_name || '';
                });
            }
        } else {
            setAttendees(Array(numTickets).fill({}));
        }
    }, [numTickets, setAttendees, contactEmail, setContactEmail]);


    useEffect(() => {
        checkErrorsEmpty(errors)
    }, [errors])

    return (
        <form onSubmit={handleSubmit}>
            <div>
                {/* Form section header (Contact Information) */}
                <h3 className="text-center font-header font-bold text-xl md:text-2xl lg:text-3xl text-primary py-2">
                    {t("place_order_window.contact_title")}
                </h3>

                <div className="h-5"></div>

                <div className="flex items-center justify-center">
                    {/* Email */}
                    <Input name='contact_email' type='email' placeholder='E-mail'
                           onChange={handleChange} errors={errors['contact_email']}
                    />
                </div>
            </div>

            <div className="h-10"></div>

            <div>
                {/* Form section header (Advance Ticket Booking) */}
                <h3 className="text-center font-header font-bold text-xl md:text-2xl lg:text-3xl text-primary py-2">
                    {t("event_modal.title")}
                </h3>

                <div className="h-5"></div>

                {attendees.map((attendee, i) =>
                    <div key={`${attendee.id}-${i}`} className="mx-auto mb-2 md:max-w-lg">
                        <h4 className="text-center font-header font-bold text-md gap-5 md:text-md text-secondary py-2 flex items-center justify-start md:justify-start">
                            {/* Remove Attendee */}
                            {attendees.length > 1 ? (
                                <div onClick={() => removeAttendee(i)}
                                     className="cursor-pointer hover:scale-95">
                                    <svg className="w-6 h-6 text-primary" aria-hidden="true"
                                         xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"
                                         viewBox="0 0 24 24">
                                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
                                              strokeWidth="2"
                                              d="M6 18 17.94 6M18 18 6.06 6"/>
                                    </svg>
                                </div>
                            ) : null}

                            {/* Attendee Information Title */}
                            <span>{t("place_order_window.attendee")} {i + 1}</span>
                        </h4>
                        <div className="flex flex-col items-start justify-center md:flex-row md:gap-5">
                            {/* Attendee (First Name) */}
                            <Input name={'attendee__first_name__' + i} type='text'
                                   placeholder={t("place_order_window.attendee_first_name") + ` ${(i + 1)}`}
                                   onChange={handleChange} errors={errors['attendee__first_name__' + i]}
                            />

                            <Input name={'attendee__last_name__' + i} type='text'
                                   placeholder={t("place_order_window.attendee_last_name") + ` ${(i + 1)}`}
                                   onChange={handleChange} errors={errors['attendee__last_name__' + i]}
                            />

                            <Input name={'attendee__email__' + i} type='email'
                                   placeholder={t("place_order_window.attendee_email") + ` ${(i + 1)}`}
                                   onChange={handleChange} errors={errors['attendee__email__' + i]}
                            />
                        </div>
                    </div>
                )}
            </div>

            <div className="h-10"></div>

            <div className="flex items-center justify-center">
                {/* Form Submit Button */}
                <p className="text-red-800 font-bold">{isFormValid}</p>
                <button type="submit" disabled={!isFormValid}
                        className="text-gray-900 bg-[#F7BE38] hover:bg-[#F7BE38]/90 focus:ring-4 focus:ring-[#F7BE38]/50 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-[#F7BE38]/50 mr-2 mb-2">
                    <svg className="mr-2 -ml-1 w-4 h-4" aria-hidden="true" focusable="false" data-prefix="fab"
                         data-icon="paypal" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                        <path fill="currentColor"
                              d="M111.4 295.9c-3.5 19.2-17.4 108.7-21.5 134-.3 1.8-1 2.5-3 2.5H12.3c-7.6 0-13.1-6.6-12.1-13.9L58.8 46.6c1.5-9.6 10.1-16.9 20-16.9 152.3 0 165.1-3.7 204 11.4 60.1 23.3 65.6 79.5 44 140.3-21.5 62.6-72.5 89.5-140.1 90.3-43.4 .7-69.5-7-75.3 24.2zM357.1 152c-1.8-1.3-2.5-1.8-3 1.3-2 11.4-5.1 22.5-8.8 33.6-39.9 113.8-150.5 103.9-204.5 103.9-6.1 0-10.1 3.3-10.9 9.4-22.6 140.4-27.1 169.7-27.1 169.7-1 7.1 3.5 12.9 10.6 12.9h63.5c8.6 0 15.7-6.3 17.4-14.9 .7-5.4-1.1 6.1 14.4-91.3 4.6-22 14.3-19.7 29.3-19.7 71 0 126.4-28.8 142.9-112.3 6.5-34.8 4.6-71.4-23.8-92.6z"></path>
                    </svg>
                    {t("place_order_window.paypal_checkout")}
                </button>
            </div>
        </form>
    )
}