import {useTranslation} from "react-i18next";

export default function About() {
    const {t, i18n} = useTranslation();

    return (
        <>
            <div className="mx-auto bg-dark grid grid-cols-1 gap-y-20 justify-items-center pb-40 pt-5">
                <h1 className="relative flex items-center justify-center text-white text-2xl md:text-3xl lg:text-4xl font-header">
                    <div style={{
                        content: '',
                        display: 'block',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        background: 'rgba(0, 0, 0, 0.5)'
                    }}></div>
                    <div className="z-20">
                        {t('about.title')}
                    </div>
                </h1>

                <div className="w-[80%] md:w-[60%] lg:w-[55%]">
                    <p className="text-white font-body text-justify text-md md:text-lg lg:text-xl">
                        {t('about.text')}
                    </p>
                </div>
            </div>
        </>
    );
}
